/**
 * UnitsCode represents the units code set in the twc-unit header,
 * or the code set in a unit-related query parameter.
 */
export type UnitsCode = |
  'e' |
  'm' |
  'h' |
  'f' |
  'c';

/**
 * Valid codes for imperial, metric, or hybrid unit systems.
 */
export type UnitsSystemCodeType = 'e' | 'm' | 'h';

export interface UnitsSystem {
  code: UnitsCode;
  name: string;
  temp: string;
  speed: string;
  distance: string;
  accumulation: string;
  precip: string;
  pressure: string;
}

export const imperial: UnitsSystem = {
  code: 'e',
  name: 'imperial',
  temp: 'F',
  speed: 'mph',
  distance: 'mi',
  accumulation: 'in',
  precip: 'in',
  pressure: 'in',
};

export const metric: UnitsSystem = {
  code: 'm',
  name: 'metric',
  temp: 'C',
  speed: 'km/h',
  distance: 'km',
  accumulation: 'cm',
  precip: 'mm',
  pressure: 'mb',
};

export const hybrid: UnitsSystem = {
  code: 'h',
  name: 'hybrid',
  temp: 'C',
  speed: 'mph',
  distance: 'mi',
  accumulation: 'in',
  precip: 'in',
  pressure: 'mb',
};

export const IMPERIAL_LOCALES: string[] = [
  'en-US',
  'es-US',
];
export const HYBRID_LOCALES: string[] = [
  'en-GB',
];
export const UNITS_COOKIE_KEY = 'unitOfMeasurement';

/**
 * Returns a UnitsSystem matching the supplied code.
 * If the code is unsupported, a null UnitsSystem is returned.
 */
export function unitsSystemByCode(code: UnitsCode): UnitsSystem {
  switch (code?.toLowerCase()) {
    case 'e':
      return imperial;
    case 'm':
      return metric;
    case 'h':
      return hybrid;
    case 'f':
      return imperial;
    case 'c':
      return metric;
    default:
      return null;
  }
}

/**
 * Returns a UnitsSystem appropriate for the given locale.
 * For all non-imperial or non-hybrid locales, the metric system is returned.
 */
export function unitsSystemByLocale(locale: string): UnitsSystem {
  if (IMPERIAL_LOCALES.includes(locale)) {
    return imperial;
  }
  if (HYBRID_LOCALES.includes(locale)) {
    return hybrid;
  }

  return metric;
}

/**
 * Returns a UnitsSystem matching the supplied name.
 * If the name is unsupported, a null UnitsSystem is returned.
 */
export function unitsSystemByName(name = ''): UnitsSystem {
  switch (name.toLowerCase()) {
    case 'imperial':
      return imperial;
    case 'metric':
      return metric;
    case 'hybrid':
      return hybrid;
    default:
      return null;
  }
}

/**
 * All of the unit-related query parameters supported by weather.com.
 */
export type UnitQuery = |
  'temp' |
  'temps' |
  'unit' |
  'units';

const unitQueries: UnitQuery[] = [
  'temp',
  'temps',
  'unit',
  'units',
];

/**
 * Removes query parameters from either an instance of URLSearchParams,
 * or from an object (to support Koa's ctx.query).
 * The supplied instance is mutated; no new instance is returned.
 */
export function removeUnitQueries(searchParams: URLSearchParams | Record<string, string>) {
  for (const key of unitQueries) {
    if (searchParams instanceof URLSearchParams) {
      searchParams.delete(key);
    } else {
      delete searchParams[key];
    }
  }
}

/**
 * Adds the `unit` query parameter, set to the supplied code.
 * If the code is not supported, the metric system code is used.
 */
export function addUnitQueryByCode(searchParams: URLSearchParams, code: UnitsCode) {
  const unitsSystem = unitsSystemByCode(code) || metric;

  searchParams.set('unit', unitsSystem.code);
}
