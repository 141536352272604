import isNil from 'lodash/isNil';
import { createSelector } from 'reselect';
import { pageHeadersSelector } from '@wxu/contexts/src/page/selectors';

export type TaboolaOverride = {
  disabled?: boolean;
  lazyloadOffset?: number;
  placementSuffix?: string;
  containerSuffix?: string;
};

export type VariantName = string;

export type VariantPayload = {
  moneytree?: boolean;
  helios?: unknown; // no need to flesh this out here
  taboola?: TaboolaOverride;
};

export interface ABExperiment {
  value: string,
  payloads: Record<VariantName, VariantPayload>,
}

export type ABExperimentObj = {[key: string]: ABExperiment};

/**
 * A/B testing experiment variation selector
 * Returns a key value object with key as the experiment key name and value is its payload
 * Example:
 * {
 *    sample-experiment: {
 *      value: "a",
 *      payload: { }
 *    }
 * }
 * It can also be overriden with query param:
 *    twc-ab-variation=eyJoZWxpb3MiOnsidmFsdWUiOiJiIn19 // {"helios":{"value":"b"}}
 */
export const experimentSelector = createSelector(
  pageHeadersSelector,
  (headers) => {
    let variation: ABExperimentObj = {};
    let variationStr;
    const encodedHeaderVariation = (headers?.['twc-variation'] ?? '');

    if (__CLIENT__) {
      variationStr = window?.atob(encodedHeaderVariation);
    } else {
      variationStr = Buffer.from(encodedHeaderVariation, 'base64');
    }

    if (variationStr !== '') {
      try {
        variation = JSON.parse(variationStr);
      } catch (err) {
        //
      }
    }
    return variation;
  },
);

// Returns the experiment name.
// There is only 1 experiment running at one time.
export const experimentNameSelector = createSelector(
  experimentSelector,
  (expVariations) => Object.keys(expVariations)[0] ?? ''
);

// Returns the experiment value which is the variant
// Can be overridden by query param: "?twc-ab-variant=a"
export const experimentVariantSelector = createSelector(
  experimentSelector,
  experimentNameSelector,
  (expVariations, name) => expVariations?.[name]?.value ?? ''
);

/*
* Returns the specified experiment name variant payload object
*/
export const experimentVariantPayloadSelector = createSelector(
  experimentSelector,
  experimentNameSelector,
  experimentVariantSelector,
  (expVariationObj, name, variant) => expVariationObj?.[name]?.payloads?.[variant]
);

// Returns reporting key for the active variant, e.g. sdk_a, sdk_b
// or undefined if no experiment is active.
// This key is used in Instana and GAM reporting.
export const experimentReportingKeySelector = createSelector(
  experimentNameSelector,
  experimentVariantSelector,
  (experimentName, variantName) => {
    if (experimentName && variantName) {
      return `${experimentName}_${variantName}`;
    }
    return undefined;
  }
);

export const taboolaOverridesSelector = createSelector(
  experimentVariantSelector,
  experimentVariantPayloadSelector,
  (variantName, variantPayload) => {
    const { taboola } = variantPayload ?? {};

    if (isNil(taboola)) return {};

    return {
      placementSuffix: ` - ${variantName}`,
      containerSuffix: `---${variantName}`,
      ...taboola,
    };
  }
);
