import { createLogger } from '@wxu/logger';
import { LS_STORE_KEY } from './constants';
import { isLocalStorageAvailable } from './isAvailable';

const logger = createLogger('web-storage');

/**
 * Asynchronously set an item to localStorage.
 * @param {string} key
 * @param {*}      value
 */
export function setItem(key, value) {
  try {
    if (isLocalStorageAvailable()) {

      let validValue;

      switch (typeof value) {
        case 'string':
        case 'boolean':
        case 'object':
        case 'number':
        case 'symbol':
          validValue = value;
          break;
        case 'undefined':
        default:
          validValue = 'null';
      }

      const valueString = JSON.stringify(validValue);

      localStorage.setItem(key, valueString);
    } else {
      throw new Error('setItem - localStorage is not available');
    }
  } catch (err) {
    logger.error(err);
  }
}

/**
 * Asynchronously get an item from localStorage.
 * @param  {string}  key
 * @param  {Object}  [options]
 * @param  {boolean} [options.isJson]
 * @return {Promise<string|Object>}
 */
export function getItem(key, { isJson = true } = {}) {
  try {
    if (isLocalStorageAvailable()) {
      const rawItem = localStorage.getItem(key);

      if (isJson) {
        try {
          return JSON.parse(rawItem);
        } catch (err) {
          //
        }
      }

      return rawItem;
    }

    throw new Error('getItem - localStorage is not available');

  } catch (err) {
    logger.error(err);
  }

  return null;
}

/**
 * Asynchronously remove an item from localStorage.
 * @param  {string}  key
 * @param  {Object}  [options]
 * @param  {boolean} [options.isJson]
 */
export function removeItem(key) {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.removeItem(key);
    } else {
      throw new Error('removeItem - localStorage is not available');
    }
  } catch (err) {
    logger.error(err);
  }
}

/**
 * Gets the appropriate key for persisted data in localStorage.
 * @param  {string} key
 * @param  {string} [storeName]
 * @return {string}
 */
export function getLocalStorageKey(key, storeName = LS_STORE_KEY) {
  return `${storeName}:${key}`;
}
